import React from "react";
import SideNavbar from "./SideNavbar";

const SidebarMenu = () => {


    return (

        <div className=" bg-white  rounded-xl overflow-y-auto">
            <SideNavbar />
        </div>


    );
}

export default SidebarMenu;
